








































































































import CommonData from "@/modules/cabinet/CommonData.vue";
import DetailData from "@/modules/cabinet/DetailData.vue";
import { Profile } from "@/modules/cabinet/profile.interface";
import Vue from "vue";

export default Vue.extend({
  components: { DetailData, CommonData },

  props: {},

  data() {
    return {
      tab: null,
      items: ["Общие данные", "Детальная информация", "Отзывы и Рейтинги", "Настройки"],
      profile: null as Profile | null,
    };
  },

  async created() {
    await this.loadProfile();
  },

  methods: {
    async loadProfile() {
      const { data } = await this.$api.get("v1/mis/profile");
      this.profile = data?.data || null;
      console.log({ ...data?.data });
    },
  },
});
