











































































































import DatePicker from "@/core/components/DatePicker.vue";
import { VSelectItem } from "@/core/interfaces/v-select.interface";
import { CityReference } from "@/core/references/city";
import { CountryReference } from "@/core/references/country";
import { StreetReference } from "@/core/references/street";
import { Profile } from "@/modules/cabinet/profile.interface";
import Vue, { PropType } from "vue";
import { PrivacyParametersReference } from "@/core/references/privacy-parameters";
import { SexReference } from "@/core/references/sex/sex.reference";

export default Vue.extend({
  name: "CommonData",
  components: { DatePicker },

  props: {
    profile: {
      type: Object as PropType<Profile>,
      required: true,
    },
  },

  data() {
    return {
      sexList: [] as VSelectItem[],
      privacyParameterList: [] as VSelectItem[],
      countriesList: [] as VSelectItem[],
      citiesList: [] as VSelectItem[],
      citiesLoading: false,
      streetsList: [] as VSelectItem[],
      streetsLoading: false,
      form: {
        avatar: null as unknown as File,
        login: this.profile.login,
        nickname: this.profile.nickname,
        last_name: this.profile.last_name,
        show_last_name: this.profile.show_last_name,
        first_name: this.profile.first_name,
        show_first_name: this.profile.show_first_name,
        middle_name: this.profile.middle_name,
        show_middle_name: this.profile.show_middle_name,
        birthday: this.profile.birthday,
        show_birthday: this.profile.show_birthday,
        phone: this.profile.phone,
        show_phone: this.profile.show_phone,
        sex: this.profile.sex,
        show_sex: this.profile.show_sex,
        country: this.profile.country,
        show_country: this.profile.show_country,
        city_code: this.profile.city_code,
        show_city: this.profile.show_city,
        street_code: this.profile.street_code,
        show_street_code: this.profile.show_street_code,
        address: this.profile.address,
      },
    };
  },

  computed: {},

  async created() {
    await this.loadData();
    await this.loadReferences();
  },

  methods: {
    async loadData() {
      try {
        // Грузим данные для города
        if (this.form.country && this.form.city_code) {
          const response = await this.$registry.get(CityReference).getByCode(this.form.country, this.form.city_code);
          if (response) {
            this.citiesList = [{ text: response.name, value: response.code }];
          }
        }

        // Грузим данные улицы
        if (this.form.country && this.form.city_code && this.form.street_code) {
          const response = await this.$registry
            .get(StreetReference)
            .getByCode(this.form.country, this.form.city_code, this.form.street_code);
          if (response) {
            this.streetsList = [{ text: response.name, value: response.code }];
          }
        }
      } catch (e) {
        console.log(e.response);
        alert("Ошибка загрузки данных профиля");
      }
    },

    async loadReferences() {
      const [sexList, privacyParameterList, countriesList] = await Promise.all([
        this.$registry.get(SexReference).getSelectItems(),
        this.$registry.get(PrivacyParametersReference).getSelectItems(),
        this.$registry.get(CountryReference).getSelectItems(),
      ]);

      this.sexList = sexList;
      this.privacyParameterList = privacyParameterList;
      this.countriesList = countriesList;
    },

    async findCity(search) {
      if (!search || search.length < 2) {
        return;
      }

      if (!this.form.country) {
        return;
      }

      await this.$registry.get(CityReference).getList(this.form.country, search);
      this.citiesList = this.$registry.get(CityReference).getSelectItems();
    },

    async findStreet(search) {
      if (!search || search.length < 2) {
        return;
      }

      if (!this.form.country || !this.form.city_code) {
        return;
      }

      await this.$registry.get(StreetReference).getList(this.form.country, this.form.city_code, search);
      this.streetsList = this.$registry.get(StreetReference).getSelectItems();
    },

    async submit() {
      try {
        const formData = new FormData();
        for (const fieldKey in this.form) {
          if (fieldKey === "avatar") {
            if (this.form[fieldKey] instanceof File) {
              formData.append(fieldKey, this.form[fieldKey], this.form[fieldKey]?.name);
            }

            continue;
          }

          formData.append(fieldKey, this.form[fieldKey]);
        }

        const { data } = await this.$api.post("v1/mis/profile", formData);
        console.log("submit", data);
      } catch (e) {
        console.log(e.response);
        alert("Ошибка отправки данных профиля");
      }
    },
  },
});
