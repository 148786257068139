



































































































































import DatePicker from "@/core/components/DatePicker.vue";
import { VSelectItem } from "@/core/interfaces/v-select.interface";
import { ClinicsReference } from "@/core/references/clinics";
import { DoctorCategoriesReference } from "@/core/references/doctor-categories";
import { DoctorEducationsReference } from "@/core/references/doctor-educations";
import { ScientificDegreesReference } from "@/core/references/scientific-degrees";
import { SpecializationsReference } from "@/core/references/specializations";
import { UniversitiesReference } from "@/core/references/universities";
import { Profile, ProfileSpecialization } from "@/modules/cabinet/profile.interface";
import Vue, { PropType } from "vue";
import jsonToFormData from "@ajoelp/json-to-formdata";

export default Vue.extend({
  name: "DetailData",
  components: { DatePicker },
  props: {
    profile: {
      type: Object as PropType<Profile>,
      required: true,
    },
  },

  data() {
    return {
      specializations: [] as VSelectItem[],
      selectedSpecialization: null as VSelectItem | null,
      specializationsLoading: false,
      clinics: [] as VSelectItem[],
      selectedClinic: null as VSelectItem | null,
      clinicsLoading: false,
      universities: [] as VSelectItem[],
      selectedUniversity: null as VSelectItem | null,
      universitiesLoading: false,
      categories: [] as VSelectItem[],
      scientificDegrees: [] as VSelectItem[],
      educations: [] as VSelectItem[],
      form: {
        specializations: this.profile.specializations,
        clinics: this.profile.clinics,
        universities: this.profile.universities,
        doctor_category: this.profile.doctor_category,
        license_number: this.profile.license_number,
        license_date: this.profile.license_date,
        short_description: this.profile.short_description,
        long_description: this.profile.long_description,
        scientific_degree: this.profile.scientific_degree,
        education: this.profile.education,
        files: [] as File[],
      },
    };
  },

  async created() {
    await this.loadReferences();
  },

  methods: {
    async loadReferences() {
      const [categories, scientificDegrees, educations] = await Promise.all([
        this.$registry.get(DoctorCategoriesReference).getSelectItems(),
        this.$registry.get(ScientificDegreesReference).getSelectItems(),
        this.$registry.get(DoctorEducationsReference).getSelectItems(),
      ]);

      this.categories = categories;
      this.scientificDegrees = scientificDegrees;
      this.educations = educations;
    },

    removeFormReferenceItem(filed: string, item: { id: number }): void {
      this.form[filed] = this.form[filed].filter((fieldItem) => fieldItem.id !== item.id);
    },

    async findSpecialization(query) {
      if (!query || query.length < 2) {
        return;
      }

      await this.$registry.get(SpecializationsReference).getList(query);
      this.specializations = this.$registry.get(SpecializationsReference).getDoctorSelectItems();
    },

    addSpecialization(): void {
      if (!this.selectedSpecialization) {
        (this.$refs.specializationsFiled as HTMLElement).focus();
        return;
      }

      this.form.specializations.push({
        id: this.selectedSpecialization.value as number,
        name: this.selectedSpecialization.text as string,
      });
      this.selectedSpecialization = null;
    },

    async findClinic(query) {
      if (!query || query.length < 2) {
        return;
      }

      await this.$registry.get(ClinicsReference).getList(query);
      this.clinics = this.$registry.get(ClinicsReference).getSelectItems();
    },

    addClinic(): void {
      if (!this.selectedClinic) {
        (this.$refs.clinicsField as HTMLElement).focus();
        return;
      }

      this.form.clinics.push({
        id: this.selectedClinic.value as number,
        name: this.selectedClinic.text as string,
        work_date_from: null,
        work_date_to: null,
      });
      this.selectedClinic = null;
    },

    async findUniversity(query) {
      if (!query || query.length < 2) {
        return;
      }

      await this.$registry.get(UniversitiesReference).getList(query);
      this.universities = this.$registry.get(UniversitiesReference).getSelectItems();
    },

    addUniversity(): void {
      if (!this.selectedUniversity) {
        (this.$refs.universitiesField as HTMLElement).focus();
        return;
      }

      this.form.universities.push({
        id: this.selectedUniversity.value as number,
        name: this.selectedUniversity.text as string,
        finish_date: "",
      });
      this.selectedUniversity = null;
    },

    // async openAttachFile(item: FileML) {},

    async onSubmit() {
      try {
        const { data } = await this.$api.post(
          "v1/mis/profile",
          jsonToFormData({
            ...this.form,
            specializations: this.form.specializations.map((s: ProfileSpecialization) => s.id),
          }),
        );
        console.log("submit", data);
      } catch (e) {
        console.log(e.response);
        alert("Ошибка отправки данных профиля");
      }
    },
  },
});
