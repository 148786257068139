<template>
  <div>
    <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px" v-model="menu">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-bind="attrs"
          v-on="on"
          placeholder="Укажите дату"
          prepend-icon="event"
          readonly
          :disabled="disabled"
          :hide-details="hideDetails"
          :value="preparedValue"
          :label="label"
          :error-messages="errorMessages"
          @input="$emit('input', $event)"
          @blur="$emit('blur', $event)"
        />
      </template>

      <v-date-picker v-model="date" no-title scrollable locale="ru" :readonly="readonly">
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="menu = false">Отменить</v-btn>
        <v-btn v-if="!readonly && !hideClearBtn" text color="primary" @click="clearDateFilter">Очистить</v-btn>
        <v-btn v-if="!readonly" text color="primary" @click="updateDateFilter">Применить</v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "DatePicker",

  props: {
    label: {
      type: String,
      default: "Укажите дату",
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    hideDetails: { type: Boolean, default: true },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    hideClearBtn: { type: Boolean, default: false },
    value: {},
  },

  data: () => ({
    menu: false,
    date: null,
  }),

  computed: {
    preparedValue() {
      const date = this.$date(this.value);
      return date.isValid() ? date.format("YYYY-MM-DD") : "";
    },
  },

  methods: {
    clearDateFilter() {
      this.menu = false;
      this.$emit("input", "");
      this.$emit("change", "");
    },

    updateDateFilter() {
      this.menu = false;
      this.$emit("input", this.date);
      this.$emit("change", this.date);
    },
  },
};
</script>

<style scoped></style>
